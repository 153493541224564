import React, { useEffect, useMemo, useState } from 'react';
import SingleProduct from './Product/SingleProduct';
import { get_Category, get_Product } from '../../../../Apis/POSApis/POSApi';
import { useQuery } from 'react-query';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { Col, Row } from 'reactstrap';
import useScanDetection from 'use-scan-detection';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProductList = ({ createOrderCart, createOrderLoader, userId }) => {
   const [filterDrop, setFilterDrop] = useState({
      category: '',
      categoryId: '',
      filter: ''
   });
   const [isScend, setISScend] = useState(false);
   const [productData, setProductData] = useState([]);
   const [pageNumber, setPageNumber] = useState(1);
   const [isNotEmptyProduct, setIsNotEmptyProduct] = useState(true);

   useScanDetection({
      onComplete: (e) => {
         setISScend(!isScend);
         setFilterDrop((prev) => {
            return { ...prev, filter: e };
         });
      },
      minLength: 3
   });

   /**
    * category dropdown api call
    */
   let paramsObj = useMemo(() => {
      let params = {
         search: { name: filterDrop.category, isActive: true }
      };
      return params;
   }, [filterDrop.category]);

   let { data } = useQuery(['getCategory', paramsObj], async () => await get_Category(paramsObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   const handleChangeCategory = (e) => {
      setFilterDrop((prev) => {
         return {
            ...prev,
            categoryId: e.target.value
         };
      });
   };

   /**
    * product api call for product listing
    */

   const getProductData = async () => {
      try {
         let params = {
            search: { isActive: true },
            recordsPerPage: 30,
            pageNumber
         };
         if (filterDrop.categoryId) {
            params.search['catId'] = filterDrop.categoryId;
         }
         if (filterDrop.filter) {
            params.search['filter'] = filterDrop.filter;
         }
         const { data } = await get_Product(params);
         if (data.status === 200) {
            setProductData([...productData, ...data.payload.data]);
            if (data.payload.data.length) {
               setIsNotEmptyProduct(true);
            }
            if (data?.payload?.data?.length === 1) {
               createOrderCart({
                  productId: data?.payload?.data[0].productId,
                  qty: 1,
                  prodType: data?.payload?.data[0].prodType,
                  userId
               });
            }
         }
      } catch (error) {}
   };

   useEffect(() => {
      getProductData();
   }, [filterDrop.filter, isScend, pageNumber]);

   return (
      <div className='tabel-contant'>
         <div className={`tabelContent`}>
            <div className='tabelSearch border-bottom'>
               <div className='text-900 font-medium text-2xl mb-4'>
                  Product Section
                  <Row>
                     <Col md='6 mb-1'>
                        <FormDataFeild
                           label={''}
                           name='pCatId'
                           id='pCatId'
                           placeholder={'All Category'}
                           value={filterDrop.categoryId}
                           handleChange={handleChangeCategory}
                           dropdown={true}
                           option={data?.data?.payload?.data?.map((elem) => {
                              return {
                                 value: elem?.catId,
                                 name: elem?.name
                              };
                           })}
                           search={(e) =>
                              setFilterDrop((prev) => {
                                 return {
                                    ...prev,
                                    category: e.filter
                                 };
                              })
                           }
                        />
                     </Col>
                     <Col md='6 mb-1'>
                        <FormDataFeild
                           label={''}
                           name='filter'
                           id='filter'
                           placeholder={'Product Search ...'}
                           value={filterDrop.filter}
                           handleChange={(e) => {
                              setFilterDrop((prev) => {
                                 return {
                                    ...prev,
                                    filter: e.target.value
                                 };
                              });
                           }}
                        />
                     </Col>
                  </Row>
               </div>
            </div>
            <div
               style={{
                  height: '40rem',
                  overflow: 'hidden',
                  overflowY: 'scroll'
               }}
               id='pos-product-list'>
               <InfiniteScroll
                  dataLength={productData.length}
                  next={() => {
                     setPageNumber((prev) => prev + 1);
                  }}
                  hasMore={isNotEmptyProduct}
                  loader={isNotEmptyProduct ? <h4 className='my-2 text-center'>Loading...</h4> : null}
                  scrollableTarget='pos-product-list'>
                  <div className='d-flex flex-wrap'>
                     {productData?.map((elem) => {
                        return (
                           <SingleProduct
                              key={elem.productId}
                              elem={elem}
                              createOrderCart={createOrderCart}
                              createOrderLoader={createOrderLoader}
                              userId={userId}
                           />
                        );
                     })}
                  </div>
               </InfiniteScroll>
            </div>
         </div>
      </div>
   );
};

export default ProductList;
