import axios from 'axios';
import { GET_CATEGORY_PUBLIC, GET_PRODUCTS_PUBLIC, ORDER_, ORDER_CART, PRODUCTS } from '../../constants/ApiConstant';

/**
 * get category
 * @param {params} paramsObj
 * @returns
 */
export const get_Category = (paramsObj) => {
   return axios.get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY_PUBLIC}`, {
      params: paramsObj
   });
};

export const get_Product = (paramsObj) => {
   let jwt_token = localStorage.getItem('authToken');
   return axios.get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_PUBLIC}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj
   });
};

/**
 * add order cart
 * @param {params} paramsObj
 * @returns
 */
export const Create_Order_Cart = (formData) => {
   console.log('formData >>', formData);
   let jwt_token = localStorage.getItem('authToken');
   return axios.post(`${process.env.REACT_APP_API_URL}${ORDER_CART}`, formData, {
      headers: { Authorization: jwt_token }
   });
};

/**
 * get order cart
 * @param {params} paramsObj
 * @returns
 */
export const get_Order_Cart = (paramObj) => {
   let jwt_token = localStorage.getItem('authToken');

   return axios.get(`${process.env.REACT_APP_API_URL}${ORDER_CART}`, {
      headers: { Authorization: jwt_token },
      params: paramObj
   });
};

/**
 * get order cart
 * @param {params} paramsObj
 * @returns
 */
export const delete_Order_Cart = (paramObj) => {
   let jwt_token = localStorage.getItem('authToken');

   return axios.delete(`${process.env.REACT_APP_API_URL}${ORDER_CART}`, {
      headers: { Authorization: jwt_token },
      params: paramObj
   });
};

/**
 * create order
 * @param {params} paramsObj
 * @returns
 */
export const create_Order = ({ formData }) => {
   console.log('formData >>', formData);
   let jwt_token = localStorage.getItem('authToken');
   return axios.post(`${process.env.REACT_APP_API_URL}${ORDER_}`, formData, {
      headers: { Authorization: jwt_token, 'Content-Type': 'application/json' }
   });
};
