import React, { Fragment, useContext, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation } from 'react-query';
import { Create_User, Upload_Image, createCustomer } from '../../../../Apis/userApi/UserApi';
import { Dialog } from 'primereact/dialog';
import { IsLoadingResponse, ToastMessage, isEmpty } from '../../../../utils/Helper';
import 'cropperjs/dist/cropper.css';
import { Name, Submit, TYPE_FELDS, TypeNumber, CreateUser, optionsDialCode, FileType } from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import { createCustomerSchema, createUserPasswordSchema, createUserSchema } from '../../../../utils/validationSchema';
import CropImagePopup from '../../../CropImagePopup';
import UserModuleContext from '../../../../context/UserModuleContext/UserModuleContext';

const UserCreateFormPopup = (props) => {
   const {
      setFormData,
      isEdit,
      setdialogs,
      formData,
      dialog,
      errors,
      setErrors,

      crop,
      setCrop,
      completedCrop,
      setCompletedCrop,
      scale,
      rotate,
      aspect
   } = useContext(UserModuleContext);
   const { handleOpenandClosePopup, refetch, emptyData } = props;
   const [dialogModal, setdialogsModal] = useState(false);

   /**
    *
    * @param {event } e
    * create User & update User
    */

   const { mutate: createUser, isLoading: createUserLoader } = useMutation((formData) => createCustomer(formData), {
      onSuccess: (response) => {
         if (response.status === 200) {
            refetch();
            setdialogs(false);
            emptyData();
            setErrors({});
            ToastMessage(response.status, response.data.message);
         }
      }
   });

   const handleUserCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createCustomerSchema();
         await schema.validate(formData, { abortEarly: false });
         let value = {
            ...formData,
            dialCode: formData?.dialCode?.code
         };
         createUser(isEmpty(value));
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);
         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   const { mutate: uploadImage } = useMutation((file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
   );

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeOrgAdmin = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => {
         let paramObj = {
            ...prev,
            [name]: value
         };
         return {
            ...paramObj
         };
      });
   };

   const search = (e) => {};

   return (
      <Fragment>
         <Dialog
            header={CreateUser}
            className='modal_style'
            visible={dialog}
            position='top'
            onHide={() => handleOpenandClosePopup(false)}
            draggable={false}
            resizable={false}>
            <IsLoadingResponse isLoading={createUserLoader} />
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleUserCreateAndUpdate(e)}>
                           <Row>
                              <Col md={`6 mb-4`}>
                                 <FormDataFeild
                                    label={Name}
                                    name='name'
                                    id='name'
                                    type={TYPE_FELDS.text}
                                    placeholder={Name}
                                    value={formData.name}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                                 {errors.name && <span className='error-validation'>{errors.name}</span>}
                              </Col>
                              <Col md='6 mb-4' className='d-flex gap-4 '>
                                 <div>
                                    <FormDataFeild
                                       label={'Dial Code'}
                                       name='dialCode'
                                       id='dialCode'
                                       placeholder={'Dial Code'}
                                       value={formData.dialCode}
                                       handleChange={handleChangeOrgAdmin}
                                       dropdown={true}
                                       option={optionsDialCode}
                                       search={search}
                                    />
                                 </div>
                                 <div style={{ width: '100%' }}>
                                    <FormDataFeild
                                       label={'Phone'}
                                       name='phone'
                                       id='phone'
                                       type={TYPE_FELDS.number}
                                       placeholder={'Phone'}
                                       value={formData.phone}
                                       handleChange={handleChangeOrgAdmin}
                                       maxlangth={TypeNumber.PHONE}
                                    />
                                 </div>
                              </Col>

                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Email'}
                                    name='email'
                                    id='email'
                                    type={TYPE_FELDS.email}
                                    placeholder={'Email'}
                                    value={formData.email}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Post Code *'}
                                    name='pincode'
                                    id='pincode'
                                    type={TYPE_FELDS.text}
                                    placeholder={'Post Code *'}
                                    value={formData.pincode}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                                 {errors.pincode && <span className='error-validation'>{errors.pincode}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'House No.'}
                                    name='blockNo'
                                    id='blockNo'
                                    type={TYPE_FELDS.text}
                                    placeholder={'House No.'}
                                    value={formData.blockNo}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Rode / Street name'}
                                    name='address'
                                    id='address'
                                    type={TYPE_FELDS.text}
                                    placeholder={'Rode / Street name'}
                                    value={formData.address}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Town'}
                                    name='city'
                                    id='city'
                                    type={TYPE_FELDS.text}
                                    placeholder={'Town'}
                                    value={formData.city}
                                    handleChange={handleChangeOrgAdmin}
                                 />
                              </Col>
                           </Row>

                           <Row>
                              <Col md='12 mb-4' className='btn-style'>
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createUserLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <CropImagePopup
               dialogModal={dialogModal}
               setdialogsModal={setdialogsModal}
               image={formData?.image}
               aspect={aspect}
               crop={crop}
               setCrop={setCrop}
               completedCrop={completedCrop}
               setCompletedCrop={setCompletedCrop}
               scale={scale}
               rotate={rotate}
               setFormData={setFormData}
               uploadImage={uploadImage}
            />
         </Dialog>
      </Fragment>
   );
};

export default UserCreateFormPopup;
