import moment from 'moment';

export const Invoice = (data, printContent) => {
   const getQTY = data.orderedProducts.reduce((sum, cur) => {
      return sum + cur.qty;
   }, 0);

   return `<div style="font-family: Arial, sans-serif; width: 100%;  margin: 0; padding: 0">
  <div style="padding: 5px; box-sizing: border-box">
    <div style="text-align: center; margin-bottom: 10px">
      <img
        src="https://ma-house.lon1.digitaloceanspaces.com/pay-prod/users/60591727787849625.jpg"
        alt=""
        style="max-width: 100%; height: auto"
      />
      <h2 style="margin: 5px 0; font-size: 14px">MA HOUSE LTD</h2>
      <p style="margin: 3px 0; font-size: 12px">11 high street, Snodland, Kent</p>
      <p style="margin: 3px 0; font-size: 12px">ME6 5DA</p>
      <p style="margin: 3px 0; font-size: 12px">0163430 2001</p>
    </div>
    <hr />
    <table style="width: 100%; border-collapse: collapse; font-size: 12px">
      <tbody>
        <tr>
          <td style="text-align: left; padding: 3px">Name:</td>
          <td style="text-align: right; padding: 3px">${data?.userDetail?.name}</td>
        </tr>
        <tr>
          <td style="text-align: left; padding: 3px">Date:</td>
          <td style="text-align: right; padding: 3px">${moment(data.orderDate).format('DD-MM-YYYY hh:mm')}</td>
        </tr>
        <tr>
          <td style="text-align: left; padding: 3px">Invoice No:</td>
          <td style="text-align: right; padding: 3px">${data.orderId}</td>
        </tr>
        <tr>
          <td style="text-align: left; padding: 3px">Payment Method:</td>
          <td style="text-align: right; padding: 3px; font-size: 16px; font-weight: 600">${
             data.paymentMethod === 1 ? 'Cash' : 'Card'
          }</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table style="width: 100%; border-collapse: collapse; font-size: 12px">
      <thead>
        <tr>
          <th style="text-align: left; padding: 5px">Product</th>
          <th style="text-align: right; padding: 5px">Price</th>
          <th style="text-align: right; padding: 5px">Qty</th>
          <th style="text-align: right; padding: 5px">Total</th>
        </tr>
      </thead>
      <tbody>
        ${data.orderedProducts
           ?.map(
              (elem) => `
        <tr>
          <td style="text-align: left; padding: 5px">${elem?.productDetail?.productName || ''}</td>
          <td style="text-align: right; padding: 5px">£${elem?.productDetail?.mrp || ''}</td>
          <td style="text-align: right; padding: 5px">${elem?.qty || ''}</td>
          <td style="text-align: right; padding: 5px">£${(elem?.productDetail?.sellPrice * elem?.qty).toFixed(2)}</td>
        </tr>
        `
           )
           .join('')}
        <tr>
          <td colspan="3" style="font-weight: 600; text-align: right">Total Qty: ${getQTY}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table style="width: 100%; border-collapse: collapse; font-size: 12px">
      <tbody>
        <tr style="padding: 5px">
          <td>Sub Total:</td>
          <td style="text-align: right">£${data.orderTotal.toFixed(2)}</td>
        </tr>
        <tr style="padding: 5px">
          <td>Tax (0%):</td>
          <td style="text-align: right">£0</td>
        </tr>
        <tr style="padding: 5px">
          <td style="font-size: 14px; font-weight: 600">Discount:</td>
          <td style="text-align: right; font-size: 14px; font-weight: 600">£${data.discAmt}</td>
        </tr>
        <tr style="padding: 5px">
          <td style="font-size: 14px; font-weight: 600">Total:</td>
          <td style="text-align: right; font-size: 16px; font-weight: 600">£${data.grandTotal.toFixed(2)}</td>
        </tr>
        <tr style="padding: 5px">
          <td style="font-size: 14px; font-weight: 600">Due Amount:</td>
          <td style="text-align: right; font-size: 14px; font-weight: 600">£${data.dueAmt}</td>
        </tr>
        <tr style="padding: 5px">
          <td style="font-size: 14px; font-weight: 600">Paid Amount:</td>
          <td style="text-align: right; font-size: 14px; font-weight: 600">£${data.paidAmt}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <div style="display:flex; justify-content:center">
      ${printContent}
    </div>
    <div style="padding-top: 10px; text-align: center">
      <p style="font-size: 16px">Thank you for shopping with us</p>
    </div>
    <hr />
  </div>
</div>
`;
};
