import React, { useContext } from 'react';
import { get_User } from '../../../../Apis/userApi/UserApi';
import UserContext from '../../../../context/UserContext/UserContext';
import { Logout, ToastMessage } from '../../../../utils/Helper';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import UserDataTabelContainer from './UserDataTabelContainer';
import UserCreateFormPopup from './UserCreateFormPopup';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { SLACE, USER, USER_LIST } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import CssContext from '../../../../context/css_context/CssContext/CssContext';
import { BackButton } from '../../../../reuseableComponents/buttons';

const User = () => {
   const {
      //pagination state variables
      pageSize,
      page,
      setRecord,
      sortOrder,
      sortBy,
      filter,

      //user edit condition state and data object state
      setdialogs,
      setErrors,
      dialog,
      setIsEdit,
      setFormData,
      setFilterDrop
   } = useContext(UserContext);
   const { isDashboardRedirect, setIsDashboardRedirect } = useContext(CssContext);

   const history = useNavigate();

   /**
    * get user List
    */

   let paramsObj = {
      pageNumber: page,
      recordsPerPage: pageSize,
      sortOrder,
      sortBy
   };
   if (filter.length) {
      paramsObj['search'] = { name: filter };
   }

   let { data, refetch, isLoading, error } = useQuery(['getUser', paramsObj], () => get_User(paramsObj), {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {
         if (response.status === 200) {
            setRecord(response.pager.totalRecords);
         }
      }
   });

   if (error) {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
      Logout(history, status);
   }

   const emptyData = () => {
      setFormData({
         name: null,
         phone: null,
         dialCode: { code: '+44', name: '+44' },
         roleId: null,
         email: null,
         image: null,
         assignStores: null,
         password: null,
         confirmPassword: null
      });
      setFilterDrop((prev) => {
         return { ...prev, storeFilter: '' };
      });
   };

   /**
    * Open Popup and Close Popup User
    */
   const handleOpenandClosePopup = (condition) => {
      setIsEdit(true);
      if (condition) {
         setdialogs(condition);
         emptyData();
         setErrors({});
      } else {
         setdialogs(condition);
         emptyData();
         setErrors({});
      }
   };

   const prev = () => {
      setIsDashboardRedirect({
         isShow: false,
         redirect: ''
      });
   };
   return (
      <>
         <Breadcrumb name={USER} slace={SLACE} name1={USER_LIST} />
         {isDashboardRedirect?.isShow && (
            <BackButton history={history} path={isDashboardRedirect.redirect} otherFunc={prev} />
         )}
         <UserDataTabelContainer
            isLoading={isLoading}
            data={data}
            handleOpenandClosePopup={handleOpenandClosePopup}
            refetch={refetch}
         />
         {dialog && (
            <UserCreateFormPopup
               handleOpenandClosePopup={handleOpenandClosePopup}
               refetch={refetch}
               emptyData={emptyData}
            />
         )}
      </>
   );
};

export default User;
