import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { FormDataFeild } from '../../../../reuseable/Inputs';
import { useMutation, useQuery } from 'react-query';
import { IsLoadingResponse } from '../../../../utils/Helper';
import { Submit, TYPE_FELDS, Close, Role } from '../../../../constants';
import { Button } from 'primereact/button';
import { Col, Form, Row } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { createRepairingSchema } from '../../../../utils/validationSchema';
import { REPAIRING, REPAIRING_ADD, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import { get_User } from '../../../../Apis/userApi/UserApi';
import { BackButton } from '../../../../reuseableComponents/buttons';
import { Checkbox } from 'primereact/checkbox';
import RepairingContext from '../../../../context/RepairingContext/RepairingContext';
import UserCreateFormPopup from '../UserModule/UserCreateFormPopup';
import UserModuleContext from '../../../../context/UserModuleContext/UserModuleContext';
import { RadioButton } from 'primereact/radiobutton';
import {
   createRepairOrder,
   getDetailsRepairOrderPrint,
   updateRepairOrder
} from '../../../../Apis/repairingApi/RepairingApi';

const productList = ['Mobile', 'Laptop', 'Ipad', 'Tab', 'Ipod', 'Xbox'];
const checkList = [
   'Bluetooth',
   'Power',
   'Charging',
   'Signal',
   'Icd',
   'Mic',
   'Speaker',
   'Ringer',
   'Vibrate',
   'Read sim',
   'Camara',
   'Back',
   'Housing',
   'HDMI port',
   'Charging port'
];

const RepairingCreateFormPopup = () => {
   const { setFormData, id, isEdit, formData, errors, setErrors, setIsEdit, setFileType } =
      useContext(RepairingContext);
   const { dialog, setFormData: setUserFormData, setdialogs } = useContext(UserModuleContext);
   const { repairingId } = useParams();
   const history = useNavigate();
   const [filterDrop, setFilterDrop] = useState({
      name: ''
   });

   const emptyData = () => {
      setFormData((prev) => {
         return {
            ...prev,
            userId: null,
            productType: null,
            makeModal: null,
            imeiNo: null,
            network: null,
            visibleDamage: null,
            checklist: [],
            estimateCost: null,
            depositPaid: null,
            exactReportedFault: null,
            actualCost: null,
            repairCompletedBy: null
         };
      });

      setFileType('');
   };

   const emptyUserFormData = () => {
      setUserFormData({
         name: null,
         phone: null,
         dialCode: { code: '+44', name: '+44' },
         email: null,
         pincode: null,
         address: null,
         landmark: null,
         city: null,
         blockNo: null
      });
   };

   const { mutate: repairingOrderDetails, isLoading: repairingLoader } = useMutation(
      () => getDetailsRepairOrderPrint(repairingId),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
               const payload = response.data.payload.data;
               setFormData((prev) => ({
                  userId: payload.userId,
                  productType: payload.productType,
                  makeModal: payload.makeModal,
                  imeiNo: payload.imeiNo,
                  network: payload.network,
                  visibleDamage: payload.visibleDamage,
                  checklist: payload.checklist,
                  estimateCost: payload.estimateCost,
                  depositPaid: payload.depositPaid,
                  exactReportedFault: payload.exactReportedFault,
                  actualCost: payload.actualCost,
                  repairCompletedBy: payload.repairCompletedBy
               }));
            }
         }
      }
   );

   /**
    *
    * @param {event } e
    * create repairing & update repairing
    */

   const { mutate: createRepairing, isLoading: createRepairingLoader } = useMutation(
      (formData) => createRepairOrder(formData, history, emptyData),
      {
         onSuccess: (response) => {
            if (response.status === 200) {
            }
         }
      }
   );

   const { mutate: updateDealOfTheDay, isLoading: updateRepairingLoader } = useMutation((formData) =>
      updateRepairOrder(formData, repairingId, history, emptyData)
   );

   const handleRepairingOrderCreateAndUpdate = async (e) => {
      e.preventDefault();
      try {
         let schema = createRepairingSchema();
         await schema.validate(formData, { abortEarly: false });
         if (repairingId !== 'add' && isEdit) {
            let newData = {
               ...formData,
               userId: formData?.userId?.code ?? null
            };
            updateDealOfTheDay(newData);
         } else {
            let newData = {
               ...formData,
               estimateCost: +formData.estimateCost,
               depositPaid: +formData.depositPaid,
               actualCost: +formData.actualCost
            };
            createRepairing(newData);
         }
      } catch (error) {
         const validationErrors = {};
         error.inner.forEach((validationError) => {
            validationErrors[validationError.path] = validationError.message;
         });
         setErrors(validationErrors);

         if (Object.keys(validationErrors)[0]) {
            const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
            inputField[0].focus();
         }
      }
   };

   console.log('isEdit >>', isEdit);

   /**
    *
    * @param {get {value} and {name}} e
    * input value change
    */

   const handleChangeRepairing = (e) => {
      const { name, value, checked } = e.target;
      setFormData((prev) => {
         if (name === 'checklist') {
            return {
               ...prev,
               [name]: checked ? [...prev.checklist, value] : prev.checklist.filter((elem) => elem !== value)
            };
         } else {
            return {
               ...prev,
               [name]: value
            };
         }
      });
   };

   /**
    * go to parent page
    */
   const redirectPrevious = () => {
      history(REDIRECT_PATH.REPAIRING);
      emptyData();
   };

   let paramsUserObj = useMemo(
      () => ({
         showAll: true,
         search: {
            name: filterDrop.name,
            roleId: [Role.Customer]
         }
      }),
      [filterDrop.name]
   );

   let { data, refetch } = useQuery(
      ['getUserRepairingDropdown', paramsUserObj],
      async () => await get_User(paramsUserObj),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false
      }
   );

   const handleChangeCustomer = (e) => {
      setFilterDrop((prev) => {
         return {
            ...prev,
            userId: e.target.value
         };
      });
      if (e.target.name === 'customer') {
         setFormData((prev) => ({
            ...prev,
            userId: e.target.value
         }));
      }
   };

   useEffect(() => {
      if (data?.payload?.data?.length > 0 && repairingId === 'add') {
         setFilterDrop((prev) => {
            return {
               ...prev,
               userId: data?.payload?.data[0]?.userId
            };
         });
         setFormData((prev) => ({
            ...prev,
            userId: data?.payload?.data[0]?.userId
         }));
      }
   }, [data?.payload?.data]);

   const handleOpenandClosePopup = (condition) => {
      setIsEdit(true);
      if (condition) {
         setdialogs(condition);
         emptyUserFormData();
      } else {
         setdialogs(condition);
         emptyUserFormData();
      }
   };

   useEffect(() => {
      if (repairingId !== 'add') {
         repairingOrderDetails();
      }
   }, [repairingId]);

   return (
      <Fragment>
         <div id='donationReceipt'></div>
         <Breadcrumb name={REPAIRING} slace={SLACE} name1={REPAIRING_ADD} />
         <IsLoadingResponse isLoading={createRepairingLoader || updateRepairingLoader || repairingLoader} />
         <BackButton history={history} path={REDIRECT_PATH.REPAIRING} otherFunc={emptyData} />
         <div className='card-div'>
            <Row>
               <Col sm='12'>
                  <Card>
                     <CardBody>
                        <Form className='needs-validation' onSubmit={(e) => handleRepairingOrderCreateAndUpdate(e)}>
                           <Row>
                              <Col md='6 mb-4'>
                                 <div className='d-flex align-items-end gap-2 flex-wrap'>
                                    <div style={{ width: '88%' }}>
                                       <FormDataFeild
                                          label={'Customer'}
                                          name='customer'
                                          id='customer'
                                          placeholder={'All Customer'}
                                          value={formData.userId}
                                          handleChange={handleChangeCustomer}
                                          dropdown={true}
                                          option={data?.payload?.data?.map((elem) => {
                                             return {
                                                value: elem?.userId,
                                                name: elem?.name
                                             };
                                          })}
                                          search={(e) =>
                                             setFilterDrop((prev) => {
                                                return {
                                                   ...prev,
                                                   name: e.filter
                                                };
                                             })
                                          }
                                       />
                                    </div>
                                    <div style={{ paddingBottom: '4px', width: '10%' }}>
                                       <Button
                                          icon='pi pi-plus'
                                          severity='warning'
                                          style={{ height: '40px', width: '100%' }}
                                          onClick={handleOpenandClosePopup}
                                          tooltip='Add New Customer'
                                          tooltipOptions={{ position: 'bottom' }}
                                          type='button'
                                       />
                                    </div>
                                 </div>
                                 {errors.userId && <span className='error-validation'>{errors.userId}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {'Product Detail'}
                                    </label>
                                    <div className='flex gap-3 flex-wrap justify-content-between'>
                                       {productList.map((list) => (
                                          <div className='flex align-items-center'>
                                             <RadioButton
                                                inputId={list}
                                                name={'productType'}
                                                value={list}
                                                checked={list === formData.productType}
                                                onChange={handleChangeRepairing}
                                             />
                                             <label htmlFor={list} className='ml-2'>
                                                {list}
                                             </label>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                                 {errors.productType && <span className='error-validation'>{errors.productType}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={'Make Modal'}
                                    name='makeModal'
                                    id='makeModal'
                                    placeholder={'Make Modal'}
                                    value={formData.makeModal}
                                    handleChange={handleChangeRepairing}
                                 />
                                 {errors.makeModal && <span className='error-validation'>{errors.makeModal}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={'IMEI Number'}
                                    name='imeiNo'
                                    id='imeiNo'
                                    placeholder={'IMEI Number'}
                                    value={formData.imeiNo}
                                    handleChange={handleChangeRepairing}
                                 />
                                 {errors.imeiNo && <span className='error-validation'>{errors.imeiNo}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={'Network'}
                                    name='network'
                                    id='network'
                                    placeholder={'Network'}
                                    value={formData.network}
                                    handleChange={handleChangeRepairing}
                                 />
                                 {errors.network && <span className='error-validation'>{errors.network}</span>}
                              </Col>
                              <Col md='3 mb-4'>
                                 <FormDataFeild
                                    label={'Visible Damage'}
                                    name='visibleDamage'
                                    id='visibleDamage'
                                    placeholder={'Visible Damage'}
                                    value={formData.visibleDamage}
                                    handleChange={handleChangeRepairing}
                                 />
                              </Col>
                              <Col md='12 mb-4'>
                                 <div className='flex flex-column gap-2 padding'>
                                    <label
                                       htmlFor='username'
                                       className='font-medium text-900'
                                       style={{ fontSize: '14px' }}>
                                       {'Checklist'}
                                    </label>
                                    <div className='flex gap-3 flex-wrap justify-content-between'>
                                       {checkList.map((list, i) => (
                                          <div className='flex align-items-center' key={i}>
                                             <Checkbox
                                                inputId={`ingredient${i}`}
                                                name={'checklist'}
                                                value={list}
                                                checked={formData.checklist.includes(list)}
                                                onChange={handleChangeRepairing}
                                             />
                                             <label htmlFor={`ingredient${i}`} className='ml-2'>
                                                {list}
                                             </label>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                                 {errors.checklist && <span className='error-validation'>{errors.checklist}</span>}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Estimate cost'}
                                    name='estimateCost'
                                    id='estimateCost'
                                    placeholder={'Estimate cost'}
                                    value={formData.estimateCost}
                                    type={TYPE_FELDS.number}
                                    handleChange={handleChangeRepairing}
                                 />
                                 {errors.estimateCost && (
                                    <span className='error-validation'>{errors.estimateCost}</span>
                                 )}
                              </Col>
                              <Col md='6 mb-4'>
                                 <FormDataFeild
                                    label={'Deposit Paid'}
                                    name='depositPaid'
                                    id='depositPaid'
                                    placeholder={'Deposit cost'}
                                    type={TYPE_FELDS.number}
                                    value={formData.depositPaid}
                                    handleChange={handleChangeRepairing}
                                 />
                                 {errors.depositPaid && <span className='error-validation'>{errors.depositPaid}</span>}
                              </Col>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={'Exact reported fault'}
                                    name='exactReportedFault'
                                    id='exactReportedFault'
                                    placeholder={'Exact reported fault'}
                                    value={formData.exactReportedFault}
                                    handleChange={handleChangeRepairing}
                                 />
                              </Col>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={'Actual cost'}
                                    name='actualCost'
                                    id='actualCost'
                                    placeholder={'Actual cost'}
                                    type={TYPE_FELDS.number}
                                    value={formData.actualCost}
                                    handleChange={handleChangeRepairing}
                                 />
                              </Col>
                              <Col md='4 mb-4'>
                                 <FormDataFeild
                                    label={'Repair from completed by'}
                                    name='repairCompletedBy'
                                    id='repairCompletedBy'
                                    placeholder={'Repair from completed by'}
                                    value={formData.repairCompletedBy}
                                    handleChange={handleChangeRepairing}
                                 />
                              </Col>
                           </Row>

                           <Row>
                              <Col md='12 mb-4' className='btn-style flex justify-content-between '>
                                 <Button
                                    label={Close}
                                    severity='danger'
                                    icon='pi pi-times'
                                    onClick={() => redirectPrevious()}
                                 />
                                 <Button
                                    label={Submit}
                                    icon='pi pi-check'
                                    loading={createRepairingLoader || updateRepairingLoader}
                                    type={TYPE_FELDS.submit}
                                 />
                              </Col>
                           </Row>
                        </Form>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </div>
         {dialog && (
            <UserCreateFormPopup
               handleOpenandClosePopup={handleOpenandClosePopup}
               refetch={refetch}
               emptyData={emptyUserFormData}
               isPOS={true}
            />
         )}
      </Fragment>
   );
};

export default RepairingCreateFormPopup;
