import { Button } from 'primereact/button';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { ToastMessage } from '../../../../../utils/Helper';
import { MIN_PRODUCT } from '../../../../../constants';

const SingleProduct = ({ elem, createOrderCart, createOrderLoader, userId }) => {
   return (
      <>
         <div className='card-container flex-grow-1' id={`UncontrolledTooltipExample${elem.productId}`}>
            <div className='p-2'>
               <div className='shadow-2 p-4 surface-card border-round'>
                  {/* <div className='relative mb-3'>
                     <img
                        src={elem?.defaultImage?.imagePath}
                        className='w-full'
                        alt='0'
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                     />
                  </div> */}
                  <div className='flex justify-content-between align-items-center mb-1 text-center'>
                     <span className='text-900 font-medium text-lg overflow-hidden text-truncate '>
                        {elem.productName}
                     </span>
                  </div>
                  <UncontrolledTooltip placement='bottom' target={`UncontrolledTooltipExample${elem.productId}`}>
                     {elem.productName}
                  </UncontrolledTooltip>
                  <span className='text-primary text-lg font-medium mb-1 d-block text-center'>£{elem.sellPrice}</span>
                  <div className='d-flex justify-content-center pt-1'>
                     <Button
                        icon='pi pi-cart-plus'
                        onClick={() => {
                           if (!userId) {
                              ToastMessage(1, 'Please Select user after click to place order !');
                              return;
                           }

                           if (elem.totalStock === 0) return;
                           createOrderCart({
                              productId: elem?.productId,
                              qty: 1,
                              prodType: elem?.prodType,
                              userId
                           });
                        }}
                        tooltip='Add To Cart'
                        loading={createOrderLoader}
                        severity={elem.totalStock === 0 ? 'danger' : elem.totalStock <= MIN_PRODUCT ? 'warning' : ''}
                     />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default SingleProduct;
