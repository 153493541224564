export const repairingPrint = (payload) => {
   return `<div style="margin: 0 auto; padding: 8mm;">
  
  <div style="text-align: center; margin-bottom: 10px">
        <img src="https://ma-house.lon1.digitaloceanspaces.com/pay-prod/users/60591727787849625.jpg" alt="" />
  </div>
  <div style="font-weight: bold; text-align: center; font-size: 20px; margin-bottom: 10px; ">MA HOUSE LTD
  <p style="margin: 3px 3px; font-size: 12px">11 high street, Snodland, Kent</p>
        <p style="margin: 3px 0; font-size: 12px">ME6 5DA</p></div>
  <div style="font-weight: bold; text-align: center; font-size: 30px; margin-bottom: 15px; ">Repair Receipt</div>
  <table style="width: 100%; border-collapse: collapse;">
    <tr>
      <td style="font-weight: bold; width: 100px; border: 1px solid #000; padding: 8px; vertical-align: top;">Date In:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.date} ${payload.time}</td>
    
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Name:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.customer.name}</td>
    </tr><tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Contact No.:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${
         payload.customer.phone ? payload.customer.dialCode ?? '' : ''
      } ${payload.customer.phone ?? '-'}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Address:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${
         payload.customer?.defaultAddress?.blockNo ?? ''
      } ${payload.customer?.defaultAddress?.address || '-'}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Postcode:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${
         payload.customer?.defaultAddress?.pincode ?? '-'
      }</td>
    </tr>
    
    <tr style="font-weight: bold; width: 100px; border: 1px solid #000; padding: 8px; vertical-align: top;">
        
      <td colspan="2" style="font-weight: 600; width: 100px; border: 1px solid #000; padding: 8px; vertical-align: top; text-align:center"> Product Details</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Product Details:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.productType}</td>
    </tr><tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Make:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.makeModal}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">IMEI Number:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.imeiNo}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Network:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.network}</td>
    </tr><tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Visible Damage:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.visibleDamage}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Checklist:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;"> ${payload?.checklist?.map(
         (elem) => `${elem}/ `
      )} </td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Estimate Cost:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.estimateCost}</td>
      </tr><tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Deposit Paid:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.depositPaid}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Exact Reported Fault:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.exactReportedFault}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Actual Cost:</td>
      <td  style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.actualCost}</td>
    </tr>
    <tr>
      <td style="font-weight: bold; width: 150px; border: 1px solid #000; padding: 8px; vertical-align: top;">Repair Form Completed By:</td>
      <td style="border: 1px solid #000; padding: 8px; vertical-align: top;">${payload.repairCompletedBy ?? ''}</td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 4px; vertical-align: top;font-size: 12px;">
        <p>  I agree that the above details are correct and authorize THIS COMPANY to carry out the necessary investigation as to the nature of the fault on the mobile /tab/ipad/watch/laptop/ipod, provided. An estimate of the cost of the repair will be given when the items (mobile/laptop/tab/ipad/watch/ipod) is submitted. However this
        should be considered as the final cost of repair.unless specifically quoted by us. all additional cost will be discussed with you the customer prior to work being carried out. I here by agree to pay the full amount upon collection of mobile/laptop/ipad/tab/watch/ipod. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> Please note that any handsets repaired or not which remain uncollected and/or unpaid for, after a period of 28 days from submission to ourselves, shall remain the property of this company. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 4px; vertical-align: top;font-size: 12px;">
        <p> ALL REPAIRS CARRY A 14 DAYS WARRANTY FROM THE DATE OF COLLECTION. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> NOTE: THIS ONLY COVERS THE REPAIR CARRIED OUT BY THIS COMPANY. IT DOES NOT COVER SEPARATE FAULTS WHICH MAY OCCUR IN THE FUTURE. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> All warranties shall become null and void should the mobile/laptop/ipad/watch/ipod be submitted to another retailer for investigation or repair following collection.</p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> PLEASE NOTE THAT ALL ITEMS TAKEN IN FOR REPAIR OR ESTIMATE ON REPAIR INCUR A MINIMUM NON-REFUNDABLE £20 CHARGE. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> Once inspection has commenced by this company, if repairs are carried out by this company and a minimum charge of £25 is payable should you agree for the items to be sent to an out of house repair centre for estimate or repair and this amount must be paid before any equipment is returned. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> TO BEST OF MY KNOWLEDGE THIS PHONE IS NOT STOLEN. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> ONLY SIGN THIS DOCUMENT IF YOU AGREE TO ALL OF THE ABOVE TERMS AND CONDITIONS. </p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="border: 1px solid #000; padding: 0 8px; vertical-align: top;font-size: 12px;">
        <p> THIS DOCUMENT IS LEGALLY BINDING AND DOES NOT AFFECT YOUR STATUTORY RIGHTS. </p>
      </td>
    </tr>
    <tr>
      <td  style="border: 1px solid #000; padding:  8px;font-weight: bold; vertical-align: top;font-size: 12px;"> Customer signature: </td>
      <td style="border: 1px solid #000; padding:  8px; vertical-align: top;"></td>
    </tr>
    <tr>
      <td  style="border: 1px solid #000; padding:  8px;font-weight: bold; vertical-align: top;font-size: 12px;"> Estimated time for repair in working days: </td>
      <td  style="border: 1px solid #000; padding:  8px; vertical-align: top;"></td>
    </tr>
    <tr>
      <td  style="border: 1px solid #000; padding:  8px; font-weight: bold;vertical-align: top;font-size: 12px;"> Customer signature (upon collection of items): </td>
      <td  style="border: 1px solid #000; padding:  8px; vertical-align: top;"></td>
    </tr>
    <tr>
      <td  style="border: 1px solid #000; padding:  8px;font-weight: bold; vertical-align: top;font-size: 12px;"> Date collected: </td>
      <td  style="border: 1px solid #000; padding:  8px; vertical-align: top;"></td>
    </tr>
  </table>
</div>`;
};
