import React from 'react';
import Breadcrumb from '../../../../reuseable/Breadcrumb/Breadcrumb';
import { ORDER_, ORDER_DETAIL, SLACE } from '../../../../reuseable/Breadcrumb/BreadcrumbConstant';
import { useQuery } from 'react-query';
import { get_Order_Detail } from '../../../../Apis/orderApi/OrderApi';
import { useParams } from 'react-router';
import moment from 'moment';
import {
   ORDER_STATUS_SHOW,
   PAYMENT_METHOD_SHOW,
   PAYMENT_STATUS_SHOW,
   SHIP_METHOD_SHOW,
   SHIP_STATUS_SHOW
} from '../../../../constants';
import { BackButton } from '../../../../reuseableComponents/buttons';
import { useNavigate } from 'react-router-dom';
import { REDIRECT_PATH } from '../../../../Routes';
import Logo from '../../../../assets/logo.jpeg';

const OrderDetails = () => {
   const { orderId } = useParams();
   const history = useNavigate();

   let paramObj = {
      orderId
   };

   let { data } = useQuery(['getOrderDetail', paramObj], async () => await get_Order_Detail(paramObj), {
      keepPreviousData: true,
      refetchOnWindowFocus: false
   });

   return (
      <>
         <Breadcrumb name={ORDER_} slace={SLACE} name1={ORDER_DETAIL} />
         <BackButton history={history} path={REDIRECT_PATH.ORDERS} />
         <div className='card py-8 px-6 md:px-8 overflow-auto'>
            <div className='flex flex-column align-items-start md:flex-row md:align-items-center md:justify-content-between border-bottom-1 surface-border pb-5 min-w-max'>
               <div className='flex flex-column'>
                  <img src={Logo} alt='' width={150} height={66} />
                  <div className='my-3 text-4xl font-bold text-900'>Mobile Accessories House</div>
                  <span className='mb-2'> Unit 1 1st Floor Fitzgerald House Willowcourt Avenue Harrow</span>
                  <span>UK</span>
               </div>
               <div className='flex flex-column mt-5 md:mt-0'>
                  <div className='text-2xl font-semibold text-left md:text-right mb-3'>INVOICE</div>
                  <div className='flex flex-column'>
                     <div className='flex justify-content-between align-items-center mb-2'>
                        <span className='font-semibold mr-6'>ORDER DATE</span>
                        <span>{moment(data?.orderDate).format('DD MMMM - YYYY')}</span>
                     </div>
                     <div className='flex justify-content-between align-items-center mb-2'>
                        <span className='font-semibold mr-6'>ORDER ID #</span>
                        <span>{data?.orderId}</span>
                     </div>
                     <div className='flex justify-content-between align-items-center'>
                        <span className='font-semibold mr-6'>ORDER STATUS</span>
                        <span>{ORDER_STATUS_SHOW[data?.orderStatus]}</span>
                     </div>
                     <div className='flex justify-content-between align-items-center'>
                        <span className='font-semibold mr-6'>NAME</span>
                        <span>{data?.userDetail?.name}</span>
                     </div>
                  </div>
               </div>
            </div>
            {/* <div className='mt-5 mb-8 flex flex-column'>
               <div className='mb-3 text-2xl font-medium'>BILL ADDRESS</div>
               <span className='mb-2'>{data?.billAddress?.address}</span>
               <span>{data?.billAddress?.pincode}</span>
            </div> */}
            <div className='overflow-x-auto'>
               <table className='w-full' style={{ borderCollapse: 'collapse', tableLayout: 'auto' }}>
                  <thead>
                     <tr>
                        <th className='text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Image
                        </th>
                        <th className='text-left font-semibold py-3 border-bottom-1 surface-border white-space-nowrap'>
                           Name
                        </th>
                        <th className='text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Quantity
                        </th>
                        <th className='text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Price
                        </th>
                        <th className='text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Tax
                        </th>
                        <th className='text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Tax Per
                        </th>
                        <th className='text-right font-semibold py-3 border-bottom-1 surface-border white-space-nowrap px-3'>
                           Tax Type
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     {data?.orderedProducts?.map((elem) => {
                        let productDetail = elem.productDetail;
                        return (
                           <tr key={elem.productOrderId}>
                              <td className='text-left py-3 border-bottom-1 surface-border white-space-nowrap'>
                                 <img
                                    src={productDetail?.defaultImage?.imagePath}
                                    alt={productDetail?.defaultImage?.imgAltText}
                                    width={75}
                                 />
                              </td>
                              <td className='text-left py-3 border-bottom-1 surface-border white-space-nowrap w-25 '>
                                 {productDetail?.productName}
                              </td>
                              <td className='text-right py-3 border-bottom-1 surface-border px-3'>{elem?.qty}</td>
                              <td className='text-right py-3 border-bottom-1 surface-border px-3'>
                                 {productDetail?.sellPrice}
                              </td>
                              <td className='text-right py-3 border-bottom-1 surface-border px-3'>
                                 {productDetail?.tax}
                              </td>
                              <td className='text-right py-3 border-bottom-1 surface-border px-3'>
                                 {productDetail?.taxPer}
                              </td>
                              <td className='text-right py-3 border-bottom-1 surface-border px-3'>
                                 {productDetail?.taxType}
                              </td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table>
            </div>
            <div className='flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8 border-bottom-1 surface-border pb-5'>
               <div className='font-semibold mb-3 md:mb-0'>
                  NOTES :- <span className='font-light'>{data?.notes}</span>
               </div>

               <div className='flex flex-column'>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>SUBTOTAL</span>
                     <span>{data?.orderTotal}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>TIP</span>
                     <span>{data?.tip || '0.00'}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>DIS AMT</span>
                     <span>{data?.discAmt}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>TAX</span>
                     <span>{data?.taxAmt}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>TOTAL</span>
                     <span>{data?.grandTotal}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>DUE AMT</span>
                     <span>{data?.dueAmt}</span>
                  </div>
                  <div className='flex justify-content-between align-items-center mb-2'>
                     <span className='font-semibold mr-6'>PAID AMT</span>
                     <span>{data?.paidAmt}</span>
                  </div>
               </div>
            </div>
            {/* <div className='flex flex-column md:flex-row md:align-items-start md:justify-content-between mt-8'>
               <div className='mb-8 flex flex-column'>
                  <div className='mb-3 text-2xl font-medium'>Rider Details</div>
                  <div className='flex flex-column'>
                     <div className='flex justify-content-between align-items-center mb-2'>
                        <span className='font-semibold mr-6'>NAME</span>
                        <span>{data?.deliveryOrder?.riderDetail?.userDetail?.name || '-'}</span>
                     </div>
                     <div className='flex justify-content-between align-items-center mb-2'>
                        <span className='font-semibold mr-6'>NUMBER</span>
                        <span>
                           {data?.deliveryOrder?.riderDetail?.userDetail?.phone
                              ? `${data?.deliveryOrder?.riderDetail?.userDetail?.dialCode} ${data?.deliveryOrder?.riderDetail?.userDetail?.phone}`
                              : '-'}
                        </span>
                     </div>
                     <div className='flex justify-content-between align-items-center mb-2'>
                        <span className='font-semibold mr-6'>DELIVERY LOCATION</span>
                        <span>
                           {data?.deliveryOrder?.deliveryLocation?.coordinates?.length ? (
                              <a
                                 href={`${process.env.REACT_APP_GOOGLE_LINK}?q=${data?.deliveryOrder?.deliveryLocation?.coordinates[0]},${data?.deliveryOrder?.deliveryLocation?.coordinates[1]}`}
                                 target='_blank'
                                 rel='noreferrer'>
                                 Location
                              </a>
                           ) : (
                              '-'
                           )}
                        </span>
                     </div>
                  </div>
               </div>
            </div> */}
         </div>
      </>
   );
};

export default OrderDetails;
